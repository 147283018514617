<template>
  <base-page
    class="login-request"
    centered
  >
    <base-graphic
      class="graphic"
      :image="require('@/assets/images/graphic/email-login.svg')"
      title="Email Login"
      body="Submit your email adres and you will receive a link to log you in."
    />
    <form
      class="form"
      method="POST"
      @submit.prevent="submit"
    >
      <form-input
        v-model="form.email"
        placeholder="Your email address"
        name="email"
        :disabled="loading"
        type="email"
        :invalid="form.errors.has('email')"
        :errors="form.errors.get('email')"
        full-width
        autofocus
        @input="form.errors.clear('email')"
      />
      <base-group
        align="center"
        spacing="medium"
        direction="vertical"
      >
        <base-button
          class="submit"
          label="Submit"
          :loading="loading"
          full-width
          @click="submit"
        />
        <base-button
          label="Already have a key?"
          :disabled="loading"
          link
          @click="$router.push({ name: 'login' })"
        />
      </base-group>
    </form>
  </base-page>
</template>

<script>
import Form from '@/utils/Form';

export default {
  data() {
    return {
      form: new Form({
        email: '',
      }),
    };
  },
  computed: {
    loading() {
      return this.$store.getters['auth/isLoading'];
    },
  },
  methods: {
    async submit() {
      await this.$store
        .dispatch('auth/requestLoginLink', { email: this.form.email })
        .then(() => {
          // Email sent, move to login requested route
          this.$router.push({ name: 'login-requested' });
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.form.errors.record(error.response);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.graphic {
  margin-bottom: 32px;
}
</style>
