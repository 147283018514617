import FormErrors from './FormErrors';

class Form {
  constructor(data) {
    this.originalData = data;
    this.set(data);

    this.errors = new FormErrors();
    this.working = false;
  }

  /**
   * Get form data
   */
  get data() {
    const data = {};

    Object.keys(this.originalData).forEach((key) => {
      if (this[key]) {
        data[key] = this[key];
      } else {
        data[key] = null;
      }
    });

    return data;
  }

  /**
   * Returns true if the form is on it's default values
   */
  get isDefault() {
    let defaultEncountered = false;

    Object.keys(this.originalData).forEach((key) => {
      if (this[key] !== this.originalData[key]) {
        defaultEncountered = true;
      }
    });

    return !defaultEncountered;
  }

  /**
   * Reset form
   */
  reset() {
    Object.keys(this.originalData).forEach((key) => {
      this[key] = this.originalData[key];
    });

    this.errors.clear();
  }

  /**
   * Clear form
   */
  clear() {
    Object.keys(this.originalData).forEach((key) => {
      this[key] = '';
    });
    // Clear errors
    this.errors.clear();
    // This is now the default state
    this.setDefault();
  }

  /**
   * Replace existing form data
   *
   * @param {object} data
   */
  set(data) {
    Object.keys(this.originalData).forEach((key) => {
      this[key] = data[key];
    });
    // This is now the default data
    this.setDefault();
  }

  /**
   * Update form data
   *
   * @param {object} data
   */
  update(data) {
    Object.keys(this.originalData).forEach((key) => {
      this[key] = data[key];
    });
  }

  /**
   * Set the current values as the default values
   */
  setDefault() {
    Object.keys(this.originalData).forEach((key) => {
      this.originalData[key] = this[key];
    });
  }
}

export default Form;
